@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

@import "./common/style/market.scss";
@import "./common/style/form.scss";
.item-date {
  text-align: left;
}
.page-text {
  color: #999999;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 10px;
}
