@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.btn {
  background: #7f76bd;
  width: 96px;
  height: 24px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 0 !important;
}
.btn-text {
  background: #fff;
}
